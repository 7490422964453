<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <!-- MAIN CONTENT -->
    <template v-if="page && !blocked">
      <v-alert
        v-if="!page.isActive"
        text
        type="info"
        prominent
      >
        <strong>{{page.name}} is currently await approval.</strong>
        All the normal functionality is available to you but nothing will be visible to the public until our review is complete.
      </v-alert>
      <v-row dense>
        <v-col cols="12">
          <v-card height="100%">
            <v-toolbar tabs color="color1 color1Text--text">
              <div>
                <v-toolbar-title>{{page.name}}</v-toolbar-title>
                <div v-if="user && user.vbl" class="text-caption">Id: {{page.id}}</div>
              </div>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-tabs
              background-color="color2"
              slider-color="color3"
              show-arrows
            >
              <v-tab
                v-for="(tab, i) in tabs"
                :key="i"
                :to="tab.to"
                exact
                class="color2Text--text"
                active-class="color3 color3Text--text"
              >{{tab.text}}</v-tab>
            </v-tabs>
            <router-view></router-view>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <got-blocked v-else>
      <span class="title">Sorry, you don't have permissions for this page.</span>
    </got-blocked>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import * as actions from '@/store/ActionTypes'

export default {
  props: ['username'],
  data () {
    return {
      blocked: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'theme',
      'getPageInfo',
      'color1IsDark',
      'organization'
    ]),
    userIsAdmin () {
      return this.user && this.user.isPageAdmin(this.username)
    },
    page () {
      return this.getPageInfo(this.username)
    },
    tabs () {
      return [
        { text: 'Home', to: { name: 'organization-home', params: { username: this.username } }, show: this.tabCheck('home') },
        { text: 'Staff', to: { name: 'organization-staff', params: { username: this.username } }, show: this.tabCheck('staff') },
        { text: 'Members', to: { name: 'organization-members', params: { username: this.username } }, show: this.theme.membership },
        { text: 'My Site', to: { name: 'organization-theme', params: { username: this.username } }, show: this.tabCheck('site') },
        { text: 'Images', to: { name: 'organization-images', params: { username: this.username } }, show: this.tabCheck('images') },
        { text: 'Cart Manager', to: { name: 'cart-manager', params: { username: this.username } }, show: this.tabCheck('cart') && !this.page.pointsOnly && !this.page.ratingsOnly },
        { text: 'Promos', to: { name: 'organization-promos', params: { username: this.username } }, show: this.tabCheck('promo') && !this.page.pointsOnly && !this.page.ratingsOnly },
        { text: 'Reports', to: { name: 'organization-reports', params: { username: this.username } }, show: this.page.props.includes('reports') },
        { text: 'Settings', to: { name: 'organization-settings', params: { username: this.username } }, show: this.tabCheck('settings') },
        { text: 'Players', to: { name: 'organization-players', params: { username: this.username } }, show: this.tabCheck('players') && !this.page.pointsOnly && !this.page.ratingsOnly },
        { text: 'Registration Search', to: { name: 'organization-reg-search', params: { username: this.username } }, show: this.tabCheck('reg') && !this.page.pointsOnly && !this.page.ratingsOnly },
        { text: 'Email', to: { name: 'organization-emails', params: { username: this.username } }, show: this.page.props && this.page.props.includes('email') },
        { text: 'Templates', to: { name: 'organization-templates', params: { username: this.username } }, show: this.tabCheck('templates') && !this.page.pointsOnly && !this.page.ratingsOnly },
        { text: 'Import', to: { name: 'organization-import', params: { username: this.username } }, show: this.tabCheck('import') && !this.page.pointsOnly && !this.page.ratingsOnly && (this.page.props.includes('import') || this.user.vbl) },
        { text: 'Exports', to: { name: 'organization-exports', params: { username: this.username } }, show: this.tabCheck('export') && !this.page.pointsOnly && !this.page.ratingsOnly },
        { text: 'Agreements', to: { name: 'organization-agreements', params: { username: this.username } }, show: this.tabCheck('agreements') && !this.page.pointsOnly && !this.page.ratingsOnly },
        { text: 'Affiliates', to: { name: 'organization-affiliates', params: { username: this.username } }, show: this.page.props && this.page.props.includes('affiliates') },
        { text: 'Organizations', to: { name: 'pointsystem-organizations', params: { username: this.username } }, show: this.page.pointsOnly },
        { text: 'Points', to: { name: 'pointsystem-points', params: { username: this.username } }, show: this.page.pointsOnly },
        { text: 'Ratings', to: { name: 'ratings-manager-settings', params: { username: this.username } }, show: this.page.ratingsOnly }
      ].filter(f => f.show)
    }
  },
  methods: {
    permit () {
      this.$VBL.organization.permit(this.username)
        .then(r => {
          this.$store.commit('addPage', r.data)
          this.blocked = false
          this.load()
        })
        .catch(e => {
          console.log(e.response)
          this.blocked = true
        })
    },
    load () {
      if (this.organization && this.organization.username === this.$route.params.username) return
      this.$store.dispatch(actions.LOAD_ORGANIZATION, this.$route.params.username)
    },
    tabCheck (tab) {
      if (this.user.vbl) return true
      if (!this.page) return true
      const tabProp = this.page.props && this.page.props.find(f => f.startsWith('TABS-'))
      if (!tabProp) return true
      const tabs = tabProp.replace('TABS-', '').split('-')
      return tabs.includes(tab)
    }
  },
  watch: {
    username: function (n, o) {
      if (n && o && n !== o) this.permit()
    }
  },
  mounted () {
    if (this.$route.query.stripe) {
      window.close()
    } else {
      this.permit()
    }
  },
  created () {
    if (this.username.toLowerCase() === 'tournament' || this.username.toLowerCase() === 'coachesbox') {
      this.$router.push('/tournaments')
    }
  }
}
</script>

<style>

</style>
